<template>
  <div v-if="stream">
    <div class="home-stream">
      <ImgComponent
        v-if="stream?.user?.head_img"
        :head_img="stream?.user?.head_img"
        class="home-stream__img"
      />
      <div v-else class="home-stream">
        <ImgComponent src="/static/images/sasahub.webp" class="home-stream__img" />
      </div>
      <span class="home-stream__title">LIVE</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeStreamComponent",
  props: {
    stream: {
      type: Object,
      default: () => {},
    },
    streams: Array,
    index: Number,
  },
};
</script>

<style lang="stylus">
.home-stream {
  position relative
  max-height 85px
  display flex
  flex-direction column
  align-items center
  gap 3px
  flex-shrink 0
  cursor pointer
  +above(1576px) {
    gap 10px
  }

  &__img {
    width 60px
    height 60px
    border-radius 100px
  }

  &__title {
    line-height 15px
    position absolute
    bottom 0
    left: 15px;
    width 30px
    height 15px
    background #F8A05F
    display flex
    justify-content center
    align-items center
    font-size 8px
    border-radius 6px
    border 1px solid white
    color white
  }
}
</style>
